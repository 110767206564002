<template>
  <div class="page rules">
    <BreadCrumb />
    <div class="page-body">
      <el-row>
        <el-col
          :span="7"
          class="left"
        >
          <div class="custom-tree-container">
            <div class="block">
              <p>预定义规则</p>
              <el-tree
                :data="treeData"
                :default-expanded-keys="[1]"
                :expand-on-click-node="false"
                :props="defaultProps"
                :render-content="renderContent"
                highlight-current
                node-key="id"
                @node-click="handleNodeClick"
              />
            </div>
          </div>
        </el-col>
        <el-col
          :span="16"
          class="right"
        >
          <el-table
            id="test"
            v-loading="loading"
            :data="tableData"
            fit
            stripe
          >
            <el-table-column
              label="规则名称"
              prop="templateName"
            />
            <el-table-column
              label="创建人"
              prop="createBy"
            />
            <el-table-column
              label="创建时间"
              prop="createTime"
            />
            <el-table-column
              label="更新时间"
              prop="updateTime"
            />
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
  <el-dialog
    v-model="modalBox"
    :title=" modalBoxSubmitFlag ? '新增' : '修改'"
  >
    <el-form
      ref="classification"
      :model="classification"
    >
      <el-form-item
        :label-width="120"
        label="名称"
        prop="name"
      >
        <el-input
          v-model="classification.name"
          autocomplete="off"
          placeholder="请输入名字"
          type="text"
        />
      </el-form-item>
      <el-form-item
        :label-width="120"
        label="序号"
        prop="sort"
      >
        <el-input
          v-model="classification.seq"
          :disabled="modalBoxSubmitFlag"
          autocomplete="off"
          placeholder="请输入序号"
          type="text"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button
          type="primary"
          @click="submitForm"
        >确 定
        </el-button>
        <el-button @click="modalBox = false">取 消</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import BreadCrumb from '/src/components/BreadCrumb';

export default {
	'name': 'RuleTemplate',
	'components': {
		BreadCrumb
	},
	data() {
		return {
			'modalBoxTitle': '',
			'modalBoxSubmitFlag': false,
			'modalBox': false,
			'loading': true,
			'treeData': '',
			'defaultProps': {
				'children': 'children',
				'label': 'text'
			},
			'tableData': '',
			'classification': {
				'id': null,
				'name': '',
				'seq': 0,
				'pid': null
			}
		};
	},
	'computed': {},
	'mounted': function () {
		this.refresh();
	},
	'methods': {
		refresh() {
			const _this = this;

			this.$axios.GET('/api/v1/core/project/template_cla').then(data => {
				if (data.code == 200) {
					_this.loading = false;
					_this.treeData = data.data;
				}
			});
		},
		submitForm() {
			const that = this;

			if (this.validate) {
				if (this.modalBoxSubmitFlag) {
					this.$axios.POST('/api/v1/core/project/template_cla', that.classification).then(res => that.formCallback(res));
				} else {
					this.$axios.PUT('/api/v1/core/project/template_cla', that.classification).then(res => that.formCallback(res));
				}

			}
		},
		formCallback(res) {
			const that = this;

			if (res.code == 200) {
				that.refresh();
				that.modalBox = false;
				that.$message({
					'showClose': true,
					'message': that.modalBoxSubmitFlag ? '新增成功' : '修改成功',
					'type': 'success'
				});
			}
		},
		validate() {
			this.$refs.classification.validate((valid) => {
				return valid;
			});
			return false;
		},
		renderContent(h, {
			node,
			data
		}) {
			return h('span', {'class': 'custom-tree-node'},
					h('span', null, node.label),
					h('span', null,
							h('a', {'onClick': () => this.append(data, node, true)}, '添加 '),
							h('a', {'onClick': () => this.append(data, node, false)}, '修改'),
							h('a', {'onClick': () => this.remove(data, node)}, '删除')
					));

		},
		append(data, node, flag) {
			Object.keys(this.classification).forEach(
					(key) => this.classification[key] = ''
			);
			console.log(data, node);
			const that = this;

			this.modalBoxSubmitFlag = flag;
			this.modalBox = true;
			if (!this.modalBoxSubmitFlag) {
				that.classification.name = data.text;
				that.classification.id = data.id;
				that.classification.seq = data.sort;
				that.classification.pid = node.parent.data.id ? node.parent.data.id : null;
			} else {
				that.classification.seq = node.data.children.length + 1;
				that.classification.pid = node.data.id;
			}
		},
		remove(data, node) {
			const that = this;

			this.$confirm('此操作将永久删除该数据，是否继续操作?', '提示', {
				'confirmButtonText': '确定',
				'cancelButtonText': '取消',
				'type': 'warning'
			}).then(() => {
				that.$axios.DELETE('/api/v1/core/project/template_cla/' + data.id).then(res => {
					if (res.code == 200) {
						that.modalBox = false;
						that.$message({
							'type': 'success',
							'message': '删除成功!'
						});
						const parent = node.parent,
								children = parent.data.children || parent.data,
								index = children.findIndex(d => d.id === data.id);

						children.splice(index, 1);
					}
				});
			});
		},
		handleNodeClick(data) {
			let _this = this;

			if (data.children != undefined) {
				_this.tableData = data.templates;
			}
		}
	}
};
</script>
<style scoped>
.el-table__row .cell {
	display: block;
}

.rules .el-row {
	justify-content: space-around;
}

</style>
<style>
.rules .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
	background-color: #c2dbf8;
}

.custom-tree-node {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	padding-right: 8px;
}

.custom-tree-node > span > a {
	margin-left: 10px;
}

.left {
	border: 1px solid #ddd;
	padding: 10px;
}

.right {
	border: 1px solid #ddd;
}

</style>
